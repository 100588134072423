import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmA from "src/components/modules/nonbmA"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const prdbestData = import("./data/data.json");

export default function nonbmtemplate() {

  let css;
  if(isFirefox()){
    css = `.nonbma-module--nonbma ul.nonbma-module--bullets {
        padding-left: 151px;
        }`
      }

  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`

body {
background-image: url(/assets/veggiebg.jpeg);
background-color: #3d3d3d;
background-repeat: no-repeat;
min-height: 100% !important;
width: 100% !important;
padding: 0 !important;
margin: 0 !important;
background-size: cover !important;
}

button.trbm_cta {
  font-weight: normal;
  font-size: 30px;
}

button {
  padding: 20px 50px;
  font-weight: normal;
  font-size: 30px;
}

button.install {
  border: none;
  font-size: 35px;
  font-weight: 700;
  background: #43ba19;
  border-radius: 10px;
  display: table;
  margin: 0 auto 5px;
  cursor: pointer;
  color: #fff;
}

.trbm-module--bottom h2,
.trbm-module--howto span, .footerlinks-module--br, .footerlinks-module--br a {
  color: #f1f1f1;
}

.trbm-module--bullets {
  width: 550px;
}

.cws {
  width: 200px;
  display: block;
  margin: 10px auto;
}

#ctadisclosuresearch-module--ctadisclaimersearch  {
  padding: 0px 24%;
  padding-bottom: 0;
}

#ctadisclosure-module--ctadisclaimer {
  padding: 0px 7%;
  padding-bottom: 0;
}

`}
   </style>

   <style type="text/css">
        {css}
            </style>

      <title>Freshy Search - freshysearch.com</title></Helmet>
      <NonbmA data={prdbestData}></NonbmA>
    </HomepageLayout>
  )
}
